import consumer from "./consumer"

// $(function() { ... }); で囲むことでレンダリング後に実行される
// レンダリング前に実行されると $('#messages').data('room_id') が取得できない
// turbolinks を使っている場合は $(document).on('turbolinks:load', function() { ... }); で囲う
$(function () {
  const chatChannel = consumer.subscriptions.create({ channel: 'AgendaRoomChannel', room_uuid: $('#board-list').data('room_uuid') }, {
  // const chatChannel = consumer.subscriptions.create("RoomChannel", {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    // room_channel.rbでブロードキャストされたものがここに届く
    received: function (data) {
      if (data['action']) {
        // 自身にリダイレクトする（決議・保留）
        window.location.href = "";
      } else {
        // 通常のメッセージ
        let message_html = data['message'];
        let append_html = "";
        if ($('#board-list').data('id') === data['post_user_uuid'] ) {
          append_html = '<li class="own">' + message_html + '</li>'
        } else {
          append_html = '<li class="other">' + message_html + '</li>'
        }
        return $('#board-list').append(append_html);
      }
    },

    // これが実行されるとコンシューマになったRoomChannel#speak({ message: message })が呼ばれる
    speak: function (message) {
      const $board_list = $('#board-list');
      return this.perform('speak', {
        message: message,
        room_uuid: $board_list.data('room_uuid'),
        user_uuid: $board_list.data('id')
      });
    },
  });

  // 「投稿する」ボタンを押した時の動作
  $("#comment-submit").on("click", function (event) {
    let content_message = $("#content");
    chatChannel.speak(content_message.val());
    content_message.val('');
    return event.preventDefault();
  });

  // フォーム内でエンターキーを押した時の動作
  // $(document).on('keypress', '[data-behavior~=agenda_room_speaker]', function (event) {
  //   if (event.keyCode === 13) {
  //     chatChannel.speak(event.target.value);
  //     // chatChannel.received(event.target);
  //     event.target.value = '';
  //     return event.preventDefault();
  //   }
  // });
});